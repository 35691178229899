import React, { FC } from 'react'
import Image from 'next/image'
import { imageLoader } from 'src/utils/loader'
import { Box, Text, Stack, Container } from '@chakra-ui/react'

const Article: FC<PressGlobal.PressItem> = ({ excerpt, image, target }) => (
  <Container>
    <Box
      h="2.75rem"
      w="12.75rem"
      mx="auto"
      mb={-5}
      pos="relative"
      borderRadius={4}
      overflow="hidden"
      bg="baseWhite"
      borderColor="borders.grey"
      borderWidth="1px"
      boxShadow="shadows.cards"
    >
      <Image
        loader={imageLoader}
        unoptimized
        src={image.url}
        loading="lazy"
        decoding="async"
        layout="fill"
        className="imagePadding10"
      />
    </Box>
    <a href={target} title={excerpt} target="_blank" rel="noreferrer">
      <Box
        w="full"
        bg="baseWhite"
        rounded="md"
        p="xl"
        borderRadius={4}
        overflow="hidden"
        borderColor="borders.grey"
        borderWidth="1px"
        boxShadow="shadows.cards"
        h="12rem"
      >
        <Stack>
          <Text
            fontFamily="Montserrat-Medium"
            fontSize=".875rem"
            color="baseDark"
            lineHeight="18px"
            mt={{ base: 0, md: '1.3rem' }}
          >
            {excerpt}
          </Text>
        </Stack>
      </Box>
    </a>
  </Container>
)

export default Article
